import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    backgroundColor: '#ebf4fc',
    borderRadius: '15px',
    padding: '10px 15px 0px 15px',
    margin: '4px 30px 10px 30px',
  },
  checkBoxGroupLabel: {
    ...theme.typography.tableCell,
    float: 'left',
    fontWeight: 'bold',
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
    },
  },
  checkboxLabel: {
    ...theme.typography.tableCell,
    color: theme.palette.gray0,
    paddingRight: '0.8rem',
  },
  checkbox: {
    paddingRight: '0.3rem',
    color: theme.palette.gray3,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
});

const ServiceTypeSelectionComponent = ({
  classes,
  arrAllServiceType,
  arrSelectedServiceType,
  updateSelectedServiceTypes,
}) => {
  const [setSelectedServiceTypeId, setSetSelectedServiceTypeId] = useState(
    new Set(arrSelectedServiceType.map((selectedServiceType) => selectedServiceType.serviceTypeId))
  );

  useEffect(() => {
    setSetSelectedServiceTypeId(
      new Set(
        arrSelectedServiceType.map((selectedServiceType) => selectedServiceType.serviceTypeId)
      )
    );
  }, [arrSelectedServiceType]);

  const onChange = (event) => {
    const serviceTypeIdToChange = Number(event.target.value);
    const setUpdatedSelectedServiceTypeId = new Set([...setSelectedServiceTypeId]);
    if (setUpdatedSelectedServiceTypeId.has(serviceTypeIdToChange)) {
      setUpdatedSelectedServiceTypeId.delete(serviceTypeIdToChange);
    } else {
      setUpdatedSelectedServiceTypeId.add(serviceTypeIdToChange);
    }
    // To ensure that there will always be one service type selected, if the user unchecks the last checkbox, no action will be taken
    if (setUpdatedSelectedServiceTypeId.size !== 0) {
      updateSelectedServiceTypes(setUpdatedSelectedServiceTypeId);
      setSetSelectedServiceTypeId(setUpdatedSelectedServiceTypeId);
    }
  };

  return (
    <Box className={classes.root}>
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.checkBoxGroupLabel} disabled>
          Service Type
        </FormLabel>
        <FormGroup row onChange={onChange}>
          {arrAllServiceType.map((serviceType) => {
            return (
              <FormControlLabel
                key={serviceType.type}
                value={serviceType.serviceTypeId}
                control={<Checkbox className={classes.checkbox} size="small" />}
                label={
                  <Typography className={classes.checkboxLabel}>{serviceType.type}</Typography>
                }
                checked={setSelectedServiceTypeId.has(serviceType.serviceTypeId)}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default withTheme(withStyles(styles)(ServiceTypeSelectionComponent));
