import moment from 'moment';
import React, { PureComponent } from 'react';
import Media from 'react-media';
import { withRouter, Link } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Badge,
  Button,
  Box,
  Breadcrumbs,
  IconButton,
  Grid,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';

import DatePickerModal from './DatePickerModal';
import NotificationBar from './NotificationBar';
import ProfileMenu from './ProfileMenu';
import Sidebar from './Sidebar';
import { AppContext, CONSTANT } from '../../AppContext';
import Logo from '../../imgs/logo.png';

const GROUP_BY_DISPLAY_STRING = {
  [CONSTANT.groupByDay]: 'Day',
  [CONSTANT.groupByWeek]: 'Week',
  [CONSTANT.groupByMonth]: 'Month',
};

/**
 * For conditional rendering of header bars based on scrollup/down
 */
const HEADER_HEIGHT = 57;

/**
 * Custom styles for the header component
 */
const secondHeaderBarBackgroundColor = '#F9F8FA';
const styles = (theme) => ({
  /**
   * App bar: menu icon, title logo, notifications
   */
  root: {
    flexGrow: 1,
    backgroundImage: theme.palette.primaryGradient,
    height: '100%',
  },
  appBar: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      backgroundImage: theme.palette.primaryGradient,
    },
    [theme.breakpoints.up(theme.breakpoints.values.md - 1)]: {
      backgroundImage: theme.palette.primaryGradient,
      paddingLeft: theme.main.paddingLeftRight,
      paddingRight: theme.main.paddingLeftRight,
    },
  },
  toolBar: {
    // Force height of app bar to be exactly {HEADER_HEIGHT}px
    minHeight: `${HEADER_HEIGHT}px`,
    maxHeight: `${HEADER_HEIGHT}px`,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  logoStyle: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      maxHeight: '45px',
      verticalAlign: 'middle',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md - 1)]: {
      maxHeight: '45px',
      position: 'absolute',
      top: '-22px',
      left: '-59px',
    },
  },
  badgeStyle: {
    transform: 'scale(0.8) translate(30%, -30%)',
    backgroundColor: theme.palette.info.main,
    color: 'white',
  },
  logoItem: {
    position: 'relative',
  },
  notifItem: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginRight: '-10px',
      paddingLeft: '10px',
    },
  },
  profileStyle: {
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      maxHeight: '45px',
      verticalAlign: 'middle',
      paddingTop: '5px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      maxHeight: '45px',
      verticalAlign: 'middle',
      paddingTop: '6px',
    },
  },

  /**
   * Second header bar: breadcrumb, date picker, group data dropdown
   */
  secondHeaderBar: {
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.11)',
    backgroundColor: secondHeaderBarBackgroundColor,
    paddingTop: '10px',
    paddingBottom: '6px',
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
  },
  secondHeaderBarSticky: {
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.45)',
    backgroundColor: secondHeaderBarBackgroundColor,
    paddingTop: '10px',
    paddingBottom: '6px',
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    position: 'fixed',
    top: '0',
    zIndex: 2,
    width: '100%',
  },
  // Breadcrumb
  dateAndGroupByPickerRow: {
    justifyContent: 'space-between',
  },
  breadcrumbGridItem: {
    marginBottom: '5px',
    marginLeft: '8px',
  },
  breadCrumbStyle: {
    ...theme.typography.breadcrumb,
    color: theme.palette.gray2,
    textDecoration: 'none',
  },
  breadcrumbActive: {
    ...theme.typography.breadcrumb,
  },
  breadCrumbDivider: {
    ...theme.typography.breadcrumb,
  },
  // Date picker button
  buttonStyle: {
    boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.12)',
    borderRadius: '12px',
    backgroundColor: 'white',
    color: theme.palette.gray1,
    padding: '6px 18px',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
  calendarIconStyle: {
    maxHeight: theme.typography.body2.fontSize * 1.2,
  },
  helperText: {
    color: theme.palette.gray1,
    fontSize: '0.8rem',
    fontWeight: 600,
    padding: '1px 18px 0px 6px',
  },

  headerGrid: {
    position: 'relative',
    width: '100%',
  },
  switchUserButton: {
    margin: '15px',
    backgroundColor: theme.palette.primary.main3,
    padding: '8px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main2,
    },
  },
});

/**
 * Change the date format from YYYY-MM-DD' to D MMM YYYY for visualisation purpose
 * @param {string} dateInYYYYMMDDFormat - Date in YYYYMMDD format
 * @returns {string} dateInDMMMYYYYFormat - Date in DMMMYYYY format
 */
const getDateInDMMMYYYYFormat = (dateInYYYYMMDDFormat) => {
  const dateMoment = moment(dateInYYYYMMDDFormat, 'YYYY-MM-DD');
  const dateInDMMMYYYYFormat = dateMoment.format('D MMM YY');
  return dateInDMMMYYYYFormat;
};

// Display the number of unread notifications
const getUnreadNotifications = () => {
  // Empty
};

const getTabClassName = (tabName) => {
  return window.location.href.indexOf(tabName) > -1;
};

/**
 * Check if user is at the homepage, which is for rendering the tab's style class
 */
const isUserAtHomePage = () => {
  if (window.location.pathname === '/') {
    return true;
  }

  return false;
};

/**
 * Hides date picker and group by dropdown if the user is at the settings or contact us page
 */
const toggleDateAndGroupBy = () => {
  if (
    window.location.href.indexOf('settings') > -1 ||
    window.location.href.indexOf('contactus') > -1
  ) {
    return false;
  }
  return true;
};

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDatePickerModalOpen: false,
      isNotificationBarOpen: false,
      isSidebarOpen: false,
      isDisplayStickySecondBar: false,
      isProfileMenuOpen: false,
      anchorEl: null,
    };
  }

  componentDidMount() {
    getUnreadNotifications();
    window.addEventListener('scroll', () => this.handleScroll(), { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll());
  }

  /**
   * Handles scroll-up or scroll-down events. If the first header bar is scrolled to be out of view, the second header bar will become sticky at the top of the viewpoint.
   */
  handleScroll() {
    const firstHeaderBarHeight = document.getElementById('first-header-bar').offsetHeight;
    if (window.scrollY >= firstHeaderBarHeight) {
      this.setIsDisplaySecondBar(true);
    } else {
      this.setIsDisplaySecondBar(false);
    }
  }

  onClickLogout() {
    const { setIsAppDataFetched } = this.context;
    const { auth0 } = this.props;
    const { logout } = auth0;

    setIsAppDataFetched(false);
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      },
    });
  }

  /**
   * Direct to Change Password page from sidebar or profile menu, and close the component
   */
  onClickDirectToChangePasswordPage(type) {
    const { history } = this.props;

    if (type === 'sidebar') {
      this.closeSidebar();
    } else {
      this.closeProfileMenu();
    }
    history.push('/change-password');
  }

  /**
   * Direct to Cover Input page from sidebar or profile menu, and close the component
   */
  onClickDirectToCoverInputPage(type) {
    const { history } = this.props;

    if (type === 'sidebar') {
      this.closeSidebar();
    } else {
      this.closeProfileMenu();
    }
    history.push('/cover-input');
  }

  /**
   * Direct to Menu Item Mapping page from sidebar or profile menu, and close the component
   */
  onClickDirectToMenuItemMappingPage(type) {
    const { history } = this.props;

    if (type === 'sidebar') {
      this.closeSidebar();
    } else {
      this.closeProfileMenu();
    }
    history.push('/menu-item-mapping');
  }

  /**
   * Direct to Waste Input page from sidebar or profile menu, and close the component
   */
  onClickDirectToWasteInputPage(type) {
    const { history } = this.props;

    if (type === 'sidebar') {
      this.closeSidebar();
    } else {
      this.closeProfileMenu();
    }
    history.push('/waste-input');
  }

  /**
   * Direct to Home page from sidebar or profile menu, and close the component
   */
  onClickDirectToHomePage(type) {
    const { history } = this.props;

    if (type === 'sidebar') {
      this.closeSidebar();
    } else {
      this.closeProfileMenu();
    }
    history.push('/');
  }

  onClickCloseSidebar() {
    this.closeSidebar();
  }

  onCloseSidebar() {
    this.closeSidebar();
  }

  onClickOpenProfileMenu(event) {
    this.setState({ isProfileMenuOpen: true, anchorEl: event.currentTarget });
  }

  onCloseProfileMenu() {
    this.closeProfileMenu();
  }

  onCloseNotificationBar() {
    this.closeNotificationBar();
  }

  onClickNotificationBar() {
    this.closeNotificationBar();
  }

  onClickOpenDatePickerModal() {
    this.setState({ isDatePickerModalOpen: true });
  }

  onClickOpenNotificationBar() {
    this.setState({ isNotificationBarOpen: true });
  }

  onClickOpenSidebar() {
    this.setState({ isSidebarOpen: true });
  }

  setIsDisplaySecondBar(boolean) {
    this.setState({
      isDisplayStickySecondBar: boolean,
    });
  }

  closeDatePickerModal() {
    this.setState({ isDatePickerModalOpen: false });
  }

  closeProfileMenu() {
    this.setState({ isProfileMenuOpen: false });
  }

  closeNotificationBar() {
    this.setState({ isNotificationBarOpen: false });
  }

  closeSidebar() {
    this.setState({ isSidebarOpen: false });
  }

  render() {
    const {
      companyLogoURL,
      selectedGroupBy,
      pageHistory,
      selectedEndDate,
      selectedStartDate,
      numberOfUnreadNotifications,
      lastDayLuxonToDisplayWasteAnalysis,
    } = this.context;
    const { auth0, classes, arrNotification, arrRestaurantService, history, theme } = this.props;
    const {
      anchorEl,
      isDisplayStickySecondBar,
      isNotificationBarOpen,
      isProfileMenuOpen,
      isSidebarOpen,
      isDatePickerModalOpen,
    } = this.state;
    const { user } = auth0;

    const isDisableDatePickerButton = // Also used to determine if the second header bar should be sticky when the first header bar is out of the viewpoint
      pageHistory[0] === CONSTANT.changePasswordPage ||
      pageHistory[0] === CONSTANT.coverInputPage ||
      pageHistory[0] === CONSTANT.menuItemMappingPage ||
      pageHistory[0] === CONSTANT.wasteInputPage;

    return (
      <>
        <Grid item className={classes.headerGrid}>
          {/* App bar */}
          <Box id="first-header-bar" className={`${classes.root}`}>
            <AppBar position="relative" className={classes.appBar} elevation={0}>
              <Toolbar classes={{ regular: classes.toolBar }}>
                <Media
                  query={`(max-width: ${theme.breakpoints.values.md - 1}px)`}
                  render={() => (
                    <IconButton edge="start" color="inherit" aria-label="menu">
                      <MenuIcon onClick={() => this.onClickOpenSidebar()} />
                    </IconButton>
                  )}
                />
                <Button className={classes.title}>
                  <Grid item className={classes.logoItem}>
                    <Link to="/">
                      <img src={Logo} className={classes.logoStyle} alt="Lumitics" />
                    </Link>
                  </Grid>
                </Button>
                <Media
                  query={`(min-width: ${theme.breakpoints.values.md}px)`}
                  render={() => (
                    <Grid container direction="row" justifyContent="flex-end">
                      {user.isAdmin ? (
                        <Grid item>
                          <Button
                            className={classes.switchUserButton}
                            variant="contained"
                            onClick={() => history.push('/impersonator')}
                          >
                            <CompareArrowsIcon />
                            <Typography variant="body2">Impersonator</Typography>
                          </Button>
                        </Grid>
                      ) : null}

                      <Grid item className={classes.profileStyle}>
                        <Link to="/">
                          <img
                            src={companyLogoURL}
                            className={classes.profileStyle}
                            alt="Profile"
                          />
                        </Link>
                      </Grid>
                      <Grid item>
                        <ProfileMenu
                          open={isProfileMenuOpen}
                          anchorEl={anchorEl}
                          onClickLogout={() => this.onClickLogout()}
                          onClickDirectToChangePasswordPage={(type) =>
                            this.onClickDirectToChangePasswordPage(type)
                          }
                          onClickDirectToCoverInputPage={(type) =>
                            this.onClickDirectToCoverInputPage(type)
                          }
                          onClickDirectToMenuItemMappingPage={(type) =>
                            this.onClickDirectToMenuItemMappingPage(type)
                          }
                          onClickDirectToWasteInputPage={(type) =>
                            this.onClickDirectToWasteInputPage(type)
                          }
                          onClickDirectToHomePage={(type) => this.onClickDirectToHomePage(type)}
                          onCloseProfileMenu={() => this.onCloseProfileMenu()}
                          onClickOpenProfileMenu={(event) => this.onClickOpenProfileMenu(event)}
                          getTabClassName={getTabClassName}
                          isUserAtHomePage={isUserAtHomePage}
                        />
                      </Grid>
                    </Grid>
                  )}
                />
                <Grid className={classes.notifItem}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => this.onClickOpenNotificationBar()}
                  >
                    <Badge
                      badgeContent={numberOfUnreadNotifications}
                      classes={{
                        anchorOriginTopRightRectangle: classes.badgeStyle,
                      }}
                    >
                      <NotificationsIcon style={{ color: 'white' }} />
                    </Badge>
                  </IconButton>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            id="second-header- bar"
            className={`${
              isDisplayStickySecondBar && !isDisableDatePickerButton
                ? classes.secondHeaderBarSticky
                : classes.secondHeaderBar
            }`}
          >
            <Grid container direction="column">
              {/* Breadcrumbs */}
              <Grid item className={classes.breadcrumbGridItem}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumbDivider}>
                  <Link to="/" className={classes.breadCrumbStyle}>
                    Home
                  </Link>
                  {/* Style history and active breadcrumb items */}
                  {pageHistory.map((pageName, index) =>
                    index < pageHistory.length - 1 ? (
                      <Link to="/" className={classes.breadCrumbStyle} key={pageName}>
                        {pageName}
                      </Link>
                    ) : (
                      <Typography
                        color="primary"
                        className={classes.breadcrumbActive}
                        key={pageName}
                      >
                        {pageName}
                      </Typography>
                    )
                  )}
                </Breadcrumbs>
              </Grid>

              {/* Date picker button and group by dropdown */}
              {toggleDateAndGroupBy() && (
                <Grid item style={{ width: 'fit-content' }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={`dateRangeButton ${classes.buttonStyle}`}
                        onClick={() => this.onClickOpenDatePickerModal()}
                        startIcon={
                          <DateRangeIcon color="primary" className={classes.calendarIconStyle} />
                        }
                        disabled={isDisableDatePickerButton}
                      >
                        <Typography variant="body2">
                          {`${getDateInDMMMYYYYFormat(
                            selectedStartDate
                          )} - ${getDateInDMMMYYYYFormat(selectedEndDate)} (Comparisons by: ${
                            GROUP_BY_DISPLAY_STRING[selectedGroupBy]
                          })`}
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.helperText}>
                        *Last updated:&nbsp;
                        <b>{lastDayLuxonToDisplayWasteAnalysis.toFormat('dd LLL yyyy')}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>

        {/* Modal for the date picker */}
        <DatePickerModal
          isDatePickerModalOpen={isDatePickerModalOpen}
          closeDatePickerModal={() => this.closeDatePickerModal()}
        />

        {/* Notifications panel */}
        <NotificationBar
          isNotificationBarOpen={isNotificationBarOpen}
          onCloseNotificationBar={() => this.onCloseNotificationBar()}
          onClickNotificationBar={() => this.onClickNotificationBar()}
          arrNotification={arrNotification}
          arrRestaurantService={arrRestaurantService}
        />

        {/* Notifications panel */}
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          onClickLogout={() => this.onClickLogout()}
          onClickDirectToChangePasswordPage={(type) => this.onClickDirectToChangePasswordPage(type)}
          onClickDirectToCoverInputPage={(type) => this.onClickDirectToCoverInputPage(type)}
          onClickDirectToMenuItemMappingPage={(type) =>
            this.onClickDirectToMenuItemMappingPage(type)
          }
          onClickDirectToWasteInputPage={(type) => this.onClickDirectToWasteInputPage(type)}
          onClickDirectToHomePage={(type) => this.onClickDirectToHomePage(type)}
          onClickCloseSidebar={() => this.onClickCloseSidebar()}
          onCloseSidebar={() => this.onCloseSidebar()}
          getTabClassName={getTabClassName}
          isUserAtHomePage={isUserAtHomePage}
        />
      </>
    );
  }
}

Header.contextType = AppContext;

export default withRouter(withAuth0(withTheme(withStyles(styles)(Header))));
