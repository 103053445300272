import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import CustomTable from '../../common/CustomTable';
import { AppContext } from '../../../AppContext';

/**
 * This component shows a table of the top food items wasted
 * on the restaurant breakdown card, as well as having the functionality
 * to directly add items to the user's watchlist.
 *
 * @prop {string} toggleValueForWeightCost // 'weight' or 'cost'
 * @prop {Function} addToWatchlist
 * @prop {Object[]} selectedLocationWasteAnalysisForHighlights
 */

const styles = (theme) => ({
  componentTitle: {
    color: theme.palette.primary.main,
    paddingBottom: '12px',
  },
  buttonRowContainer: {
    marginTop: 15,
    marginBottom: 15,
  },
  enabledAddButton: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primaryGradient,
    borderRadius: '7px',
  },
  enabledCancelSelectionButton: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    borderRadius: '7px',
  },
  button: {
    borderRadius: '7px',
  },
});

class TopFoodItemsTable extends PureComponent {
  render() {
    const {
      classes,
      arrSelectedMenuItemServiceWasteAnalysis,
      toggleValueForWeightCost,
      selectedLevelWasteAnalysisForHighlights,
      onClickClearSelection,
      onClickAdd,
      updateArrSelectedMenuItemServiceWasteAnalysis,
    } = this.props;
    const { impersonatorDatavizUserId } = this.context;

    const weightCostHeader = toggleValueForWeightCost === 'weight' ? 'WEIGHT' : 'COST';
    const arrTableHeader = [
      { id: 'menuItemName', label: 'ITEM' },
      { id: 'serviceName', label: 'SERVICE' },
      { id: 'station', label: 'STATION' },
      { id: toggleValueForWeightCost, label: weightCostHeader },
    ];

    let arrTableRow = [];
    if (selectedLevelWasteAnalysisForHighlights) {
      if (toggleValueForWeightCost === 'weight') {
        arrTableRow =
          selectedLevelWasteAnalysisForHighlights.topMenuItemServiceWasteAnalysis.byWeight;
      } else {
        arrTableRow =
          selectedLevelWasteAnalysisForHighlights.topMenuItemServiceWasteAnalysis.byCost;
      }
    }

    const arrTableAlignment = ['left', 'center', 'center', 'right'];
    const arrTableWrap = [true, true, false, false];
    const arrIdKey = ['menuItemId'];
    const arrColumnWidth = ['25%', '25%', '25%', '25%'];

    const numberOfMenuItemsSelected = arrSelectedMenuItemServiceWasteAnalysis.length;
    const atLeastOneMenuItemSelected = numberOfMenuItemsSelected > 0;

    return (
      <Box id="top-five-food-items-table">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography className={classes.componentTitle} display="inline" variant="h1">
            TOP {arrTableRow.length} WASTED FOOD ITEMS
          </Typography>
        </Grid>
        <CustomTable
          arrTableHeader={arrTableHeader}
          arrTableRow={arrTableRow}
          arrTableAlignment={arrTableAlignment}
          arrTableWrap={arrTableWrap}
          toggleValueForWeightCost={toggleValueForWeightCost}
          arrSelectedRow={arrSelectedMenuItemServiceWasteAnalysis}
          updateArrSelectedRow={updateArrSelectedMenuItemServiceWasteAnalysis}
          arrIdKey={arrIdKey}
          hasSelectAllCheckboxInHeader={impersonatorDatavizUserId !== -1}
          hasCheckboxInBody={impersonatorDatavizUserId !== -1}
          arrColumnWidth={arrColumnWidth}
        />
        {impersonatorDatavizUserId !== -1 && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            className={classes.buttonRowContainer}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                className={
                  atLeastOneMenuItemSelected ? classes.enabledCancelSelectionButton : classes.button
                }
                disabled={!atLeastOneMenuItemSelected}
                onClick={onClickClearSelection}
              >
                <Typography variant="h6">Clear Selection</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                className={atLeastOneMenuItemSelected ? classes.enabledAddButton : classes.button}
                disabled={!atLeastOneMenuItemSelected}
                onClick={onClickAdd}
              >
                <Typography variant="h6">
                  Add {numberOfMenuItemsSelected || null} Item
                  {numberOfMenuItemsSelected > 1 ? 's' : null} to Watchlist
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}

TopFoodItemsTable.contextType = AppContext;

export default withStyles(styles)(TopFoodItemsTable);
